define(function(require) {
    const CC = require('CC');
    const BaseController = require('pages/Base.controller');
    const AudienceStatsPageableCollection = require('models/audience/AudienceStats.collection').default;
    const ClientModel = require('models/client/Client');
    const ClientCapabilityModel = require('models/client/ClientCapability.model');
    const LastRunModel = require('models/dashboard/LastRun.model');

    const allMembersStatsCollection = new AudienceStatsPageableCollection();
    const client = new ClientModel();
    const emailCapability = new ClientCapabilityModel();
    const lastRunModel = new LastRunModel();
    const ClientActivityView = require('./ClientActivity.view');
    const { authorize } = require('utils/authorization');

    const controller = new BaseController({
        baseView: true,
        context: '/',
        requiredModels: function() {
            const currentClientId = CC.utils.getCurrentClientId();
            client.set('id', currentClientId);
            emailCapability.setParams({
                clientId: currentClientId,
                capabilityName: 'CAP:Email_Collection'
            });
            return [client, emailCapability, lastRunModel];
        },
        navViewOptions: {
            breadcrumbViewOptions: {
                pageContext: '',
                showIcon: false
            }
        },
        dependentModels: function() {
            if (
                authorize('Audiences') &&
                CC.authorizeByPermissionAndClient('ViewAudienceStats', CC.utils.getCurrentClientId())
            ) {
                allMembersStatsCollection.setPathParam('audienceId', client.get('allMembersAudienceId'));
                allMembersStatsCollection.setQueryParams({
                    client_id: client.get('id'),
                    client_as_group: true,
                    device_graph: true,
                    date_range: 'MONTH_TO_DATE'
                });

                return [allMembersStatsCollection];
            }
        },
        routes: function(params) {
            // if the client doesn't have Audiences (or more accurately the user doesn't have permission for audience stats), send in
            // a 'blank' stat collection for the all members stats - this allows the various cards on the clientActivityView
            // to render as they have when there is no audience data to be shared
            const allMembersStatsToUse =
                authorize('Audiences') &&
                CC.authorizeByPermissionAndClient('ViewAudienceStats', CC.utils.getCurrentClientId())
                    ? allMembersStatsCollection
                    : { models: [] };
            return [
                {
                    name: 'default',
                    path: '*default',
                    module: ClientActivityView,
                    element: '.content-container',
                    pageScheme: 'page-scheme-terrene',
                    options: {
                        allMembersStatsCollection: allMembersStatsToUse,
                        client: client,
                        emailCapability: emailCapability,
                        lastRunModel: lastRunModel
                    }
                }
            ];
        }
    });

    controller.start();
    return controller;
});
