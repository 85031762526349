define(function(require) {
    const BehaviorCardTemplate = require('./BehaviorCard.template.html');
    const MetricCard = require('./MetricCard.view');
    const _ = require('underscore');
    const PercentageBarView = require('app/PercentageBar.view');
    const CC = require('CC');
    const Backbone = require('backbone');

    const BehaviorCardView = MetricCard.extend({
        template: _.template(BehaviorCardTemplate),

        defaults: function() {
            return {
                clientId: CC.utils.getCurrentClientId(),
                cardStyle: 'terrene'
            };
        },

        events: {},

        validation: function() {
            return [
                {
                    name: 'entityType',
                    required: false
                }
            ];
        },

        initialize: function(options) {
            options.footer =
                '<a class="metric-card-cta button button--tertiary content" target="_blank" href="behaviors/manage">Manage Behaviors</a>';

            self.isFirstRender = true;

            MetricCard.prototype.initialize.apply(this, arguments);
        },

        renderContent: function() {
            const self = this;
            const abbreviation = this.$('.card-body').width() > 375 ? 'standard' : 'tiny';
            const viewOptions = {
                uncategorizedBehaviorCount:
                    self.formatNumber(self.metric.get('uncategorizedBehaviorCount'), abbreviation) || 0,
                totalBehaviorCount: self.formatNumber(self.metric.get('totalBehaviorCount'), abbreviation) || 0,
                state: self.state
            };

            self.$('.card-body').html(this.template(viewOptions));

            this.renderMetric('.behavior__uncategorized', this.metric.get('uncategorizedBehaviorCount'), abbreviation);
            this.renderMetric('.behavior__total', this.metric.get('totalBehaviorCount'), abbreviation);

            self.createAndRenderSubView('.behavior-card-percentage', PercentageBarView, {
                barColor: self.convertColorVariableToHex('--chart-color-2'),
                textColor: this.convertColorVariableToHex('--neutrals-n10'),
                model: new Backbone.Model({
                    value:
                        (self.metric.get('uncategorizedBehaviorCount') / self.metric.get('totalBehaviorCount')) * 100 ||
                        0
                })
            });

            if (self.isFirstRender) {
                window.addEventListener(
                    'resize',
                    _.debounce(() => {
                        this.renderContent();
                    })
                );
                self.isFirstRender = false;
            }

            return self;
        }
    });

    return BehaviorCardView;
});
