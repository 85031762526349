define(function(require) {
    const PercentageBarTemplate = require('./PercentageBar.template.html');
    const BaseView = require('app/Base.view');
    const _ = require('underscore');
    const CC = require('CC');
    require('./PercentageBar.css');

    const percentageBar = BaseView.extend({
        template: _.template(PercentageBarTemplate),

        defaults: function() {
            return {
                columnName: 'value',
                textColor: '#000000',
                barColor: '#BEDBBE', // minty green
                model: undefined,
                textPlacement: 'inside',
                decimalPlaces: 0,
                valueFormatter: undefined
            };
        },

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults(), options);

            if (!self.model) {
                throw new Error('Model is required');
            }
            return self;
        },

        render: function() {
            const self = this;

            self.$el.html(
                self.template({
                    percent: self.hasValue(self.valueFormatter)
                        ? self.valueFormatter(self.model.get(self.columnName))
                        : CC.converters.formatDecimalPlaces(self.model.get(self.columnName), self.decimalPlaces),
                    barColor: self.barColor,
                    textColor: self.textColor,
                    textPlacement: this.textPlacement
                })
            );

            return self;
        }
    });
    return percentageBar;
});
